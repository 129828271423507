import config from "../../config";
import React, { useCallback, useMemo } from "react";
import { CallbackDoc } from "react-google-drive-picker/dist/typeDefs";
import { Box, Grid } from "@mui/material";
import { Client } from "@sumit-platforms/types";
import {
  Breadcrumbs,
  Button,
  UploadFilesModal,
  UploadFilesTable,
} from "@sumit-platforms/ui-bazar";
import { useModal } from "@sumit-platforms/ui-bazar/store";
import { useTranslation } from "react-i18next";
import { tagService } from "@sumit-platforms/ui-bazar/services";
import authService from "../../services/authService";
import { useAuth, useToast, useUploads } from "@sumit-platforms/ui-bazar/hooks";
import useClients from "../../hooks/useClients";
import { useGlobalData } from "../../store/globalData";
import { useParams } from "react-router";

import "./UploadFiles.scss";

const tagServiceInstance = tagService({
  endpoint: config.server,
});
const UploadFiles = () => {
  const { setToast } = useGlobalData();
  const { toastError } = useToast({ setToast });
  const { t } = useTranslation();
  const { user } = useAuth({ config });
  const params = useParams();
  const idClient = useMemo(() => Number(params.idClient), [params.idClient]);
  const { client } = useClients({ idClient, config });
  const { setModalContent, setModalType, clearModalContent } = useModal();
  const { uploads, handleCreateUpload } = useUploads({
    config,
    user,
    idClient,
  });

  const handleOnUpload = ({
    files,
    attachedFiles,
    googleDriveToken,
  }: {
    files: File[] | CallbackDoc[];
    attachedFiles?: Record<string, File[]>;
    googleDriveToken?: string;
  }) => {
    handleCreateUpload({
      files,
      attachedFiles,
      googleDriveToken,
      // getToken: authService.getToken,
      onFail: () => toastError(t("upload_failed")),
      client: client as Client,
    });
  };

  const openUploadFilesModal = (): void => {
    setModalContent(
      <UploadFilesModal
        firebase={config.firebase}
        closeModal={closeModal}
        onUpload={handleOnUpload}
      />
    );
  };

  const closeModal = (): void => {
    setModalType("info");
    clearModalContent();
  };

  const getCrumbs = useCallback(
    () => [
      {
        label: "Client",
        path: "/clients",
      },
      {
        label: client?.name || "",
        path: `/clients/${idClient}`,
      },
      { label: "Add job" },
    ],
    [idClient, client]
  );

  return (
    <Grid
      className="UploadFiles Page"
      container
      display={"flex"}
      justifyContent={"center"}
    >
      <Grid item xs={11} my={3}>
        <Breadcrumbs crumbs={getCrumbs()} />
      </Grid>
      <Grid item xs={11} mb={3}>
        <Box className="box" justifyContent={"space-between"}>
          <div className="uploadFilesPriceWrapper">
            <div className="secondLine">
              <span>{`${uploads.length} ${t("uploads")}`}</span>
            </div>
          </div>
          <div className="uploadFilesButtonWrapper">
            <Button
              children={t("upload_files")}
              onClick={openUploadFilesModal}
              className={"light"}
              disabled={!client}
            />
          </div>
        </Box>
      </Grid>
      <Grid item xs={11} mb={3}>
        <UploadFilesTable
          config={config}
          user={user}
          selectedClient={client}
          setToast={setToast}
          tagService={tagServiceInstance}
          featureFlags={{
            skipStt: true,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default UploadFiles;
