import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  QueryParams,
  User,
  UserFilterOptions,
  UsersMetadata,
  UserStatus,
} from "@sumit-platforms/types";
import { useTranslation } from "react-i18next";
import {
  useAuth,
  useFilters,
  useHeadCells,
  useMFA,
  useQuery,
  useSortOptions,
  useToast,
} from "@sumit-platforms/ui-bazar/hooks";
import { getUserStatusVisual } from "@sumit-platforms/ui-bazar/utils";
import {
  ConfirmModal,
  ContextOption,
  GeneralTable,
  SearchAndFilters,
} from "@sumit-platforms/ui-bazar";
import { Grid } from "@mui/material";
import { useModal } from "@sumit-platforms/ui-bazar/store";
import { UserRow } from "../Community";
import { UserService } from "@sumit-platforms/ui-bazar/services";
import {
  USERS_INITIAL_ORDER,
  USERS_INITIAL_ORDER_BY,
  USERS_INITIAL_QUERY_LIMIT,
  USERS_INITIAL_QUERY_OFFSET,
} from "@sumit-platforms/ui-bazar/constants";
import config from "../../../config";
import { useGlobalData } from "../../../store";
import { UserEditModal } from "../modals/UserEditModal/UserEditModal";

interface CommunityTableProps {
  users: User[];
  getUsers: (query?: QueryParams<UserFilterOptions>) => Promise<void>;
  metaData: Partial<UsersMetadata>;
  isLoading: boolean;
  hasMore: boolean;
  defaultQuery: UserFilterOptions;
  totalUsers: number;
}

const userService = UserService({ config });

const CommunityTable = ({
  users,
  getUsers,
  metaData,
  defaultQuery,
  hasMore,
  totalUsers,
}: CommunityTableProps) => {
  const { t } = useTranslation();
  const [userRows, setUserRows] = useState<UserRow[]>([]);
  const { setModalContent, clearModalContent, setModalType } = useModal();
  const { recover } = useMFA({ config });
  const { setToast } = useGlobalData();
  const { toastError, toastSuccess } = useToast({ setToast });
  const { filtersScheme } = useFilters({
    metaData,
    filterKeys: ["communityMemberStatus"],
  });
  const { sortOptions } = useSortOptions(["firstName", "status"]);
  const {
    onScroll,
    onSearch,
    onFilterChange,
    onSort,
    loading: isQueryLoading,
  } = useQuery<UserFilterOptions>({
    queryLimit: USERS_INITIAL_QUERY_LIMIT,
    queryOffset: USERS_INITIAL_QUERY_OFFSET,
    order: USERS_INITIAL_ORDER,
    orderBy: USERS_INITIAL_ORDER_BY,
    onBuildQuery,
    onResetOffset: () => {
      setUserRows([]);
    },
  });

  useEffect(() => {
    if (_.isArray(users)) {
      const newUserRows = users.map(createUserRow);
      setUserRows(newUserRows);
    }
  }, [users]);

  const getStatusCellDropDown = useMemo(() => {
    const { statusColor: activeColor, statusTitle: activeTitle } =
      getUserStatusVisual(UserStatus.ACTIVE);
    const { statusColor: disabledColor, statusTitle: disabledTitle } =
      getUserStatusVisual(UserStatus.DISABLED);
    const { statusColor: trainingColor, statusTitle: trainingTitle } =
      getUserStatusVisual(UserStatus.TRAINING);
    const { statusColor: rejectedColor, statusTitle: rejectedTitle } =
      getUserStatusVisual(UserStatus.REJECTED);
    const { statusColor: newMemberColor, statusTitle: newMemberTitle } =
      getUserStatusVisual(UserStatus.NEW_MEMBER);
    return (row: UserRow): ContextOption[] => [
      {
        name: activeTitle,
        bullet: {
          color: activeColor,
        },
        action: () =>
          updateUser({ id: row.id, updates: { status: UserStatus.ACTIVE } }),
        disabled: row.status === UserStatus.ACTIVE,
      },
      {
        name: trainingTitle,
        bullet: {
          color: trainingColor,
        },
        action: () =>
          updateUser({ id: row.id, updates: { status: UserStatus.TRAINING } }),
        disabled: row.status === UserStatus.TRAINING,
      },
      {
        name: newMemberTitle,
        bullet: {
          color: newMemberColor,
        },
        action: () =>
          updateUser({
            id: row.id,
            updates: { status: UserStatus.NEW_MEMBER },
          }),
        disabled: row.status === UserStatus.NEW_MEMBER,
      },
      {
        name: disabledTitle,
        bullet: {
          color: disabledColor,
        },
        action: () => {
          openConfirmModal(
            t("disable_user_confirmation_title"),
            t("disable_user_confirmation_subtitle"),
            async () =>
              updateUser({
                id: row.id,
                updates: { status: UserStatus.DISABLED },
              }),
            true
          );
        },
        disabled: row.status === UserStatus.DISABLED,
      },
      {
        name: rejectedTitle,
        bullet: {
          color: rejectedColor,
        },
        disabled: row.status === UserStatus.REJECTED,
        action: () =>
          updateUser({ id: row.id, updates: { status: UserStatus.REJECTED } }),
      },
    ];
  }, []);

  const mfaRecover = useCallback(
    async (row: UserRow) => {
      const success = await recover(row.id);
      if (success) {
        getUsers();
        toastSuccess(t("reset_mfa_success"));
      } else {
        toastError(t("reset_mfa_error"));
      }
    },
    [recover, t, toastError, toastSuccess]
  );

  const getTableContext = useCallback(
    (row: UserRow) => {
      return [
        {
          name: t("edit"),
          action: () => openEditUserModal(row),
        },
        {
          name: t("reset_mfa"),
          action: () => mfaRecover(row),
        },
      ];
    },
    [mfaRecover, t]
  );

  const { headCells } = useHeadCells({
    headCellsKeys: [
      "name",
      "contactEmail",
      "communityMemberStatus",
      "communityMemberMFA",
      "contextMenu",
    ],
    tableContextCallBack: getTableContext,
    statusCellDropDownCallBack: (row) => getStatusCellDropDown(row),
    styles: {
      name: { width: "auto", maxWidth: "auto" },
      contactEmail: { width: "40%", maxWidth: "auto" },
      contextMenu: {
        width: "5%",
      },
    },
    cellLink: {
      name: (row) => `/community/${row.id}?tab=payments`,
    },
  });

  const createUserRow = (user: User): UserRow => {
    return {
      id: user.idUser,
      name: `${user.firstName} ${user.lastName}`,
      contact: user.email,
      status: user.status || ("no_status" as UserStatus),
      paymentAndCapacity: 0,
      context: null,
      user: user,
      mfaActive: !!user.mfaFactorId,
    };
  };

  async function updateUser({
    id,
    updates,
  }: {
    id: number;
    updates: Partial<User>;
  }) {
    await userService.update(updates, id);

    setUserRows((prevState) => {
      const idx = _.findIndex(prevState, { id });
      if (idx !== undefined) {
        const cloned = [...prevState];
        cloned[idx] = { ...cloned[idx], ...updates };
        return cloned;
      }
      return prevState;
    });
  }

  const openEditUserModal = async ({ user }: { user: User }) => {
    if (!user) return;
    setModalContent(
      <UserEditModal
        cancel={closeModal}
        confirm={async (userData: Partial<User>) => {
          await handleUserEdit(user.idUser, userData);
        }}
        user={user}
      />
    );
  };

  const handleUserEdit = async (idUser: number, user: Partial<User>) => {
    try {
      console.log(user);
      await userService.update(user, idUser);
      getUsers();
      toastSuccess(t("user_update_success"));
      closeModal();
    } catch (error) {
      toastError(t("user_update_fail"));
    }
  };

  const closeModal = (): void => {
    setModalType("info");
    clearModalContent();
  };

  function openConfirmModal(
    title: string,
    message: string,
    confirm: () => void,
    closeAfterConfirm = true
  ): void {
    setModalType("danger");
    setModalContent(
      <ConfirmModal
        title={title}
        message={message}
        confirm={confirm}
        cancel={closeModal}
        closeAfterConfirm={closeAfterConfirm}
      />
    );
  }

  async function onBuildQuery({
    query,
  }: {
    query: QueryParams<UserFilterOptions>;
  }) {
    await getUsers(query);
  }

  return (
    <>
      <Grid item xs={11} mb={1}>
        <h3 className="pageSubTitle">
          {`${t("showing")} ${userRows?.length} ${t("from")} ${totalUsers} ${t(
            "community_members"
          )}`}
        </h3>
      </Grid>
      <Grid item xs={11} mb={3}>
        <SearchAndFilters
          direction={t("dir") as any}
          onSearch={onSearch}
          isLoading={isQueryLoading}
          searchPlaceholder={t("search")}
          filterBtnTitle={t("filters")}
          title={t("filter_by")}
          defaultQuery={defaultQuery}
          onFilterChange={onFilterChange}
          filters={filtersScheme}
          onSortChange={onSort}
          sortOptions={sortOptions}
        />
      </Grid>
      <Grid item xs={11}>
        <GeneralTable
          headCells={headCells}
          rows={userRows}
          allowSelect={false}
          loading={isQueryLoading}
          onLoadMore={onScroll}
          hasMore={hasMore}
        />
      </Grid>
    </>
  );
};
export default CommunityTable;
