import React, { useMemo } from "react";
import {
  CommunityPaymentResponseData,
  CommunityPaymentResponseMetaData,
  User,
} from "@sumit-platforms/types";
import { Grid } from "@mui/material";
import {
  InformationBox,
  Option,
  TabView,
  Views,
} from "@sumit-platforms/ui-bazar";
import { capitalize } from "lodash";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { userImage } from "@sumit-platforms/common-assets/images";
import CommunityMemberPaymentsOverviewTable from "./CommunityUserPaymentsOverview";

interface Props {
  user: User;
  communityPayments: CommunityPaymentResponseData;
  metaData: CommunityPaymentResponseMetaData;
  isLoading: boolean;
  openCommunityBonusModal: () => void;
}

const CommunityMemberProfile = ({
  user,
  communityPayments,
  metaData,
  isLoading,
  openCommunityBonusModal,
}: Props) => {
  const { t } = useTranslation();
  const primaryTitle = useMemo(
    () => `${user.firstName} ${user.lastName}`,
    [user]
  );
  const primarySubtitleStack = useMemo(
    () => [
      { label: capitalize(user.status) },
      {
        label: `${t("since")} ${format(
          new Date(user.createdAt as Date),
          "dd/MM/yyyy"
        )}`,
      },
    ],
    [user]
  );
  const rightSectionStack = useMemo(() => {
    return [
      { title: t("phone"), value: user.phone },
      { title: t("email"), value: user.email },
    ];
  }, [user]);

  const views = useMemo(() => {
    return {
      payments: {
        tab: "payments",
        view: (
          <CommunityMemberPaymentsOverviewTable
            user={user}
            communityPayments={communityPayments}
            metaData={metaData}
            isLoading={isLoading}
            openCommunityBonusModal={openCommunityBonusModal}
          />
        ),
      },
    } as Views;
  }, [communityPayments, isLoading, metaData, user]);

  return (
    <Grid container display={"flex"} justifyContent={"center"}>
      <Grid xs={11} item>
        <InformationBox
          imageSrc={userImage} //TODO: change this to actual user image after upload will be implemented
          primaryTitle={primaryTitle}
          primarySubtitleStack={primarySubtitleStack}
          rightSectionStack={rightSectionStack}
        />
      </Grid>
      <Grid xs={11} item>
        <TabView
          allowSearchParams={true}
          views={views}
          defaultTab={views?.payments?.tab || ""}
        ></TabView>
      </Grid>
    </Grid>
  );
};

export default CommunityMemberProfile;
