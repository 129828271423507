import axios, { InternalAxiosRequestConfig } from "axios";

axios.interceptors.request.use(
  async (
    request: InternalAxiosRequestConfig
  ): Promise<InternalAxiosRequestConfig> => {
    request.withCredentials = true;
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);
