import config from "../../config";
import _ from "lodash";
import { subHours } from "date-fns";
import classNames from "classnames";
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";
import {
  faChevronRight,
  faEdit,
  faFileExport,
  faGears,
  faNotes,
  faSquareArrowRight,
  faTrash,
  faUserPlus,
} from "@fortawesome/pro-light-svg-icons";

import {
  AssignStatus,
  Attachment,
  AttachmentType,
  BonusType,
  Client,
  Invoice,
  ExportOptions,
  Job,
  JobBonus,
  JobCommunityMembers,
  JobFilterOptions,
  JobSplitType,
  JobStatus,
  JobTypes,
  Languages,
  languages,
  NotActiveAssignmentStatuses,
  QueryParams,
  TaskStatus,
  JobInvoice,
  jobTypesOptions,
  IndexSearch,
  JobUpdateOptions,
  RerunPayload,
  JobTranslationType,
  EnrichRequestStatus,
  RerunJobStatuses,
  ENRICH_TIMEOUT_IN_HOURS,
  TranslationData,
  JobAutoTranscriptDeliveryOptions,
} from "@sumit-platforms/types";

import {
  AttachmentsModal,
  ConfirmModal,
  ContextMenu,
  ContextOption,
  ExportJobModal,
  GeneralTable,
  JobRequest,
  ScrollerContainer,
  SearchAndFilters,
  SplitJobModal,
  CreateTranslationModal,
  RerunModal,
  SummarizeJobModal,
} from "@sumit-platforms/ui-bazar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  getJobCommunityMembers,
  getJobStatusVisual,
  getTcOffsetByStartTime,
  hasCommonItem,
  isJobReadyForSummarizeOperation,
  isJobReadyForTranslation,
  manageOperaJobStatusesForQuery,
} from "@sumit-platforms/ui-bazar/utils";

import {
  AttachmentService,
  exportService as ExportService,
  InvoiceService,
  JobService,
  MediaService,
  ProjectService,
} from "@sumit-platforms/ui-bazar/services";

import { useModal } from "@sumit-platforms/ui-bazar/store";
import {
  archivedStatuses,
  availableStatusesMap,
  JOBS_INITIAL_ORDER,
  JOBS_INITIAL_ORDER_BY,
  JOBS_INITIAL_QUERY_LIMIT,
  JOBS_INITIAL_QUERY_OFFSET,
  TODAY_MIDNIGHT,
} from "@sumit-platforms/ui-bazar/constants";
import {
  useFeatureFlag,
  useFilters,
  useHeadCells,
  useQuery,
  useSortOptions,
  useToast,
} from "@sumit-platforms/ui-bazar/hooks";

import { DurationModal } from "../../components/modals/DurationModal/DurationModal";
import { JobEditModal } from "./modals/JobEditModal/JobEditModal";
import { AssignJobModal } from "../../components/modals/AssignJobModal/AssignJobModal";

import useJobs from "../../hooks/useJobs";
import { useGlobalData } from "../../store/globalData";
import assignmentService from "../../services/assignmentService";
import useAssignments from "../../hooks/useAssignments";

import "./Jobs.scss";

const jobService = JobService({ config });
const exportService = ExportService({ config });
const attachmentService = AttachmentService({ config });
const invoiceService = InvoiceService({ config });
const projectService = ProjectService({ config });
const mediaService = MediaService({ config });

interface JobRow {
  id: number;
  name: string;
  client: Client;
  type: keyof JobTypes;
  status: JobStatus;
  duration: number;
  uploaded: Date;
  delivery: Date;
  contextMenu: any;
  job: Job;
  members?: JobCommunityMembers;
  sttProgress?: number;
  bonus?: Partial<JobBonus>;
  isLoading?: boolean;
  indexSearch?: IndexSearch[];
}

export const Jobs: FC = () => {
  const { t } = useTranslation();
  const { setModalContent, clearModalContent, setModalType } = useModal();
  const [isAssignmentRequestsOpen, setIsAssignmentRequestsOpen] =
    useState(false);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [contextAnchorEl, setContextAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const { sortOptions } = useSortOptions([
    "created_at",
    "name",
    "duration",
    "status",
    "transcribe_delivery",
    "delivery",
  ]);
  const [contextMenu, setContextMenu] = useState<{
    content: ContextOption[];
    onClose?: () => void;
  }>({ content: [] });
  const { jobTypes, setToast, projects } = useGlobalData();
  const { toastError, toastSuccess, toastInfo, toastWarning } = useToast({
    setToast,
  });

  const availableStatuses = useMemo(() => {
    return availableStatusesMap.opera.jobs;
  }, []);

  const {
    onScroll,
    onSearch,
    onFilterChange,
    onSort,
    loading: isQueryLoading,
    querySearch: currentSearchQuery,
    queryFilters: currentFiltersQuery,
  } = useQuery<JobFilterOptions>({
    queryLimit: JOBS_INITIAL_QUERY_LIMIT,
    queryOffset: JOBS_INITIAL_QUERY_OFFSET,
    order: JOBS_INITIAL_ORDER,
    orderBy: JOBS_INITIAL_ORDER_BY,
    onBuildQuery,
    onResetOffset: () => {
      setJobRows([]);
    },
  });

  const {
    jobs,
    getJobs,
    jobQueryMetaData,
    getJobQueryMetaData,
    totalJobs,
    hasMore,
    isLoading: isJobsLoading,
    setJobs,
  } = useJobs({ jobType: "allJobs" });

  const bulkStatusIconRef = useRef<HTMLElement>(null);

  const [selected, setSelected] = useState<number[]>([]);
  const [jobRows, setJobRows] = useState<JobRow[]>([]);
  const selectedJobs: Job[] | null = useMemo(
    () =>
      selected.length
        ? jobs.filter(({ idJob }) => selected.includes(idJob))
        : null,
    [jobs, selected]
  );
  const isStatusChangeDisabled = useCallback(
    ({ toStatus, row }: { toStatus: JobStatus; row: JobRow }) => {
      const isAutoDeliveryActive =
        !_.isNil(row.job.autoTranscriptDelivery) &&
        row.job.autoTranscriptDelivery !==
          JobAutoTranscriptDeliveryOptions.rejected;
      switch (toStatus) {
        case JobStatus.transcribe: {
          return (
            ![
              JobStatus.review,
              JobStatus.done,
              JobStatus.pending_review,
            ].includes(row.status) ||
            (isAutoDeliveryActive && JobStatus.done === row.status)
          );
        }

        case JobStatus.review: {
          return ![JobStatus.transcribe].includes(row.status);
        }
        case JobStatus.done: {
          return ![
            JobStatus.review,
            JobStatus.transcribe,
            JobStatus.pending_review,
          ].includes(row.status);
        }

        default: {
          return true;
        }
      }
    },
    []
  );

  const getStatusCellDropDown = useMemo(() => {
    const { statusColor: openColor, statusTitle: openTitle } =
      getJobStatusVisual(JobStatus.ready);
    const { statusColor: atWorkColor, statusTitle: atWorkTitle } =
      getJobStatusVisual(JobStatus.transcribe);
    const { statusColor: pendingReviewColor, statusTitle: pendingReviewTitle } =
      getJobStatusVisual(JobStatus.pending_review);
    const { statusColor: reviewColor, statusTitle: reviewTitle } =
      getJobStatusVisual(JobStatus.review);
    const { statusColor: doneColor, statusTitle: doneTitle } =
      getJobStatusVisual(JobStatus.done);
    const { statusColor: archiveColor, statusTitle: archiveTitle } =
      getJobStatusVisual(JobStatus.archive);

    return (rows: JobRow[]): ContextOption[] => {
      const idJobs = rows?.map((row) => row.id);
      return [
        {
          name: openTitle,
          bullet: {
            color: openColor,
          },
          action: () => handleJobEdit(idJobs, { status: JobStatus.ready }),

          disabled: _.some(rows, (row) =>
            isStatusChangeDisabled({
              toStatus: JobStatus.ready,
              row,
            })
          ),
        },
        {
          name: atWorkTitle,
          bullet: {
            color: atWorkColor,
          },
          action: () =>
            handleJobStatusChange({
              idJobs,
              newStatus: JobStatus.transcribe,
            }),
          disabled: _.some(rows, (row) =>
            isStatusChangeDisabled({
              toStatus: JobStatus.transcribe,
              row,
            })
          ),
        },
        {
          name: pendingReviewTitle,
          bullet: {
            color: pendingReviewColor,
          },
          action: () =>
            handleJobEdit(idJobs, { status: JobStatus.pending_review }),
          disabled: _.some(rows, (row) =>
            isStatusChangeDisabled({
              toStatus: JobStatus.pending_review,
              row,
            })
          ),
        },
        {
          name: reviewTitle,
          bullet: {
            color: reviewColor,
          },
          action: () =>
            handleJobStatusChange({
              idJobs,
              newStatus: JobStatus.review,
            }),
          disabled: _.some(rows, (row) =>
            isStatusChangeDisabled({
              toStatus: JobStatus.review,
              row,
            })
          ),
        },
        {
          name: doneTitle,
          bullet: {
            color: doneColor,
          },
          action: () =>
            handleJobStatusChange({
              idJobs,
              newStatus: JobStatus.done,
            }),
          disabled: _.some(rows, (row) =>
            isStatusChangeDisabled({
              toStatus: JobStatus.done,
              row,
            })
          ),
        },
        {
          name: archiveTitle,
          bullet: {
            color: archiveColor,
          },
          action: () => handleJobEdit(idJobs, { status: JobStatus.archive }),
          disabled: _.some(rows, (row) =>
            isStatusChangeDisabled({
              toStatus: JobStatus.archive,
              row,
            })
          ),
        },
      ];
    };
  }, [jobs, jobRows, selected]);

  const {
    getAssignmentRequests,
    assignmentRequests,
    isLoading: isAssignmentsLoading,
  } = useAssignments();

  const { filtersScheme } = useFilters({
    metaData: {
      maxDuration: jobQueryMetaData.maxDuration,
      projects,
      jobTypes,
      clients: jobQueryMetaData.clients,
      communityMembers: jobQueryMetaData.communityMembers,
      invoices: jobQueryMetaData.invoices,
    },
    filterKeys: [
      "jobStatus",
      "projectName",
      "type",
      "languages",
      "length",
      "dueBy",
      "uploaded",
      "client",
      "communityMembers",
      "invoices",
      "split",
    ],
  });

  const selectedRows = useMemo(() => {
    return jobRows.filter((j: JobRow) => selected.includes(j.id));
  }, [selected, jobRows]);

  const isSameClient = useMemo<boolean>(() => {
    return (
      selectedRows?.every(
        (r, _i, arr) => r?.client?.idClient === arr[0]?.client?.idClient
      ) || false
    );
  }, [selectedRows]);

  const shouldDisableBulkAssign = useMemo(() => {
    if (_.isArray(selectedJobs) && selectedJobs.length < 2) return true;
    const allowedStatuses = [JobStatus.ready, JobStatus.pending, JobStatus.stt];
    const isSelectedJobsStatusesInvalid = selectedJobs?.some(
      (job) => !allowedStatuses.includes(job.status)
    );
    const isJobsNotAssignedAlready = selectedJobs?.some((job) => {
      return job.assignments?.some(
        (assignment) =>
          assignment.assignStatus === AssignStatus.accepted ||
          assignment.taskStatus === TaskStatus.work
      );
    });
    return isSelectedJobsStatusesInvalid || isJobsNotAssignedAlready;
  }, [jobs, selectedJobs]);

  const shouldDisabledBulkStatus = useMemo(() => {
    if (selected.length < 2) return true;
    const isAllStatusesNotAllowed = _.every(
      getStatusCellDropDown(selectedRows || []),
      (contextOption) => contextOption.disabled
    );
    return isAllStatusesNotAllowed;
  }, [selected]);

  const defaultQuery: JobFilterOptions = useMemo(
    () => ({
      status: [],
      projectIds: [],
      type: [],
      inputLanguage: [],
      outputLanguage: [],
      durationStart: 0,
      durationEnd: jobQueryMetaData.maxDuration,
      deliveryStart: TODAY_MIDNIGHT,
      deliveryEnd: TODAY_MIDNIGHT,
      uploadedStart: TODAY_MIDNIGHT,
      uploadedEnd: TODAY_MIDNIGHT,
      clientIds: [],
      userIds: [],
      invoiceIds: [],
      parentChild: [],
    }),
    [jobQueryMetaData]
  );

  useEffect(() => {
    getJobQueryMetaData({ filters: { status: availableStatuses } });
  }, [availableStatuses]);

  const deleteJob = async (idJob: number[]) => {
    try {
      await jobService.deleteJob(idJob);
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const handleJobDelete = (row: JobRow) => {
    openConfirmModal(
      t("delete_job"),
      t("are_you_sure"),
      async () => {
        await deleteJob([row.id]);
        setJobs(() => {
          return jobs.filter((job) => row.id !== job.idJob);
        });
      },
      true
    );
  };

  const handleDeleteMany = () => {
    openConfirmModal(
      t("delete_jobs"),
      t("are_you_sure"),
      async () => {
        await deleteJob(selected);
        setJobs(() => {
          return jobs.filter((jobs) => !selected.includes(jobs.idJob));
        });
        setSelected([]);
      },
      true
    );
  };

  const handleBulkStatus = () => {
    setContextAnchorEl(bulkStatusIconRef.current);
    setContextMenu({
      content: getStatusCellDropDown(selectedRows || []),
      onClose: onBulkStatusClose,
    });
    setIsContextMenuOpen(true);
  };
  const onBulkStatusClose = () => {
    setContextAnchorEl(null);
    setContextMenu({ content: [] });
    setIsContextMenuOpen(false);
  };

  const handleExportJob = async (
    exportArgs: ExportOptions & {
      jobIds: number[];
      fileName: string;
    }
  ) => {
    try {
      await exportService.exportJob(exportArgs);
    } catch (err) {
      toastError(t("export_failed"));
    } finally {
      closeModal();
    }
  };

  const handleSummarizeJob = async (idJob: number, fileName: string) => {
    try {
      await exportService.createJobSummary({ idJob, fileName });
    } catch (err) {
      toastError(t("export_brief_failed"));
    } finally {
      closeModal();
    }
  };

  const openSummarizeJobModal = (idJob: number, jobName: string) => {
    setModalContent(
      <SummarizeJobModal
        idJob={idJob}
        jobName={jobName}
        onApprove={handleSummarizeJob}
        onCancel={closeModal}
      />
    );
  };

  const closeModal = (): void => {
    setModalType("info");
    clearModalContent();
  };

  const handleExportMany = () => {
    const selectedJobTypes = selectedRows.map((s) => s.type);
    const commonJobType = selectedJobTypes.every(
      (t) => t === selectedJobTypes[0]
    )
      ? selectedJobTypes[0]
      : undefined;

    let templates: any[] = [];
    if (selectedRows[0].client?.templates?.length > 0) {
      templates = selectedRows[0].client?.templates;
    }

    openExportJobModal({
      jobIds: selected,
      jobType: commonJobType,
      templates,
    });
  };

  const removeAttachment = async (
    idJob: number,
    idAttachment: number
  ): Promise<void> => {
    try {
      await jobService.removeAttachmentFromJob(idJob, idAttachment);
      const jobRow = jobRows.find((j: JobRow) => j.id === idJob);
      if (jobRow) {
        jobRow.job.attachments = jobRow.job.attachments?.filter(
          (a) => a.idAttachment !== idAttachment
        );
      }
      setJobRows((prev: JobRow[]) =>
        jobRow ? _.uniqBy([...prev, jobRow], "id") : prev
      );
      toastSuccess(t("delete_attachment_success"));
    } catch (err) {
      console.error(err);
      toastError(t("delete_attachment_failed"));
    }
  };

  const uploadAttachment = async (
    type: AttachmentType,
    attachment: File,
    jobIds: number[],
    idClient: number
  ): Promise<Attachment | null> => {
    try {
      const _attachment: Attachment = await jobService.addAttachmentToJob(
        type,
        jobIds,
        idClient,
        attachment
      );
      setJobRows((prevJobRows: any) =>
        prevJobRows.map((jobRow: JobRow) =>
          !jobIds.includes(jobRow.id)
            ? jobRow
            : {
                ...jobRow,
                job: {
                  ...jobRow.job,
                  attachments: [
                    ...(jobRow.job?.attachments || []),
                    _attachment,
                  ],
                },
              }
        )
      );
      toastSuccess(t("updating_uploads_success"));
      setSelected([]);
      if (jobIds.length > 1) {
        closeModal();
      }
      return _attachment;
    } catch (e) {
      toastError(t("updating_uploads_fail"));
      return null;
    }
  };

  //TODO: implement for every job update in future
  const handleOnModifyJobAssignment = async (idJobs: number[]) => {
    let jobsToUpdate = [];
    const affectedJobIdsToGet = [];

    //GETTING ALL UPDATED JOBS
    const { jobs: _jobs } = await jobService.getJobs({
      filters: { idJob: idJobs },
    });

    if (_.isEmpty(_jobs)) return;

    //GETTING AFFECTED SPLIT JOBS IDS
    const splitJob = _jobs.find((_job) => _job.jobSplit?.sourceJob);
    if (splitJob?.jobSplit?.sourceJob)
      affectedJobIdsToGet.push(splitJob.jobSplit.sourceJob.idJob);

    //GETTING AFFECTED TRANSLATION JOBS IDS
    _jobs.map((_job) => {
      _job.jobsTranslations?.map((jobTranslation) => {
        if (jobTranslation.job) {
          affectedJobIdsToGet.push(jobTranslation.job.idJob);
        }
      });
    });

    //GETTING UPDATED JOBS WITH AFFECTED JOBS
    if (!_.isEmpty(affectedJobIdsToGet)) {
      const { jobs: _affectedJobsIncluded } = await jobService.getJobs({
        filters: { idJob: [...idJobs, ...affectedJobIdsToGet] },
      });
      jobsToUpdate = _affectedJobsIncluded;
    } else {
      jobsToUpdate = _jobs;
    }

    setJobs((prevJobs) => {
      const updatedJobs = [...prevJobs];
      _.each(jobsToUpdate, (jobToUpdate) => {
        const jobIndex = _.findIndex(updatedJobs, { idJob: jobToUpdate.idJob });
        updatedJobs[jobIndex] = jobToUpdate;
      });
      return updatedJobs;
    });
  };

  const handleJobEdit = async (
    jobIds: number[],
    job: Partial<Job>,
    jobUpdateOptions?: JobUpdateOptions
  ) => {
    try {
      await jobService.update(jobIds, job, jobUpdateOptions);
      await handleOnModifyJobAssignment(jobIds);
      closeModal();
      toastSuccess(t("job_update_success"));
    } catch (err) {
      console.log(err);
      toastError(t("job_update_failed"));
    }
  };

  const createNewInvoice = async ({
    idClient,
    invoiceNumber,
  }: {
    invoiceNumber: string;
    idClient: number;
  }) => {
    if (!invoiceNumber || !idClient) return;

    toastInfo(t("adding_new_invoice"));
    try {
      const newInvoice = await invoiceService.createNewInvoice({
        invoice: { invoiceNumber, idClient },
      });
      const idJobsToUpdate = jobs
        .filter((job) => job.client?.idClient === idClient)
        .map((job) => job.idJob);

      await handleOnModifyJobAssignment(idJobsToUpdate);
      toastSuccess(t("new_invoice_added"));

      return newInvoice;
    } catch (e) {
      toastError(t("new_invoice_failed"));
      console.error(e);
    }
  };

  const createNewProject = async ({
    idClient,
    projectName,
  }: {
    idClient: number;
    projectName: string;
  }) => {
    if (!projectName || !idClient) return;
    try {
      const newProject = await projectService.createNewProject({
        name: projectName,
        idClient,
      });
      const idJobsToUpdate = jobs
        .filter((job) => job.client?.idClient === idClient)
        .map((job) => job.idJob);
      await handleOnModifyJobAssignment(idJobsToUpdate);
      toastSuccess(t("project_creation_succeed"));
      return newProject;
    } catch (e) {
      toastError(t("project_creation_failed"));
      console.error(e);
    }
  };

  const handleJobStatusChange = async ({
    idJobs,
    newStatus,
  }: {
    idJobs: number[];
    newStatus: JobStatus;
  }) => {
    try {
      toastInfo(t("change_job_status"));
      await jobService.updateJobStatus(idJobs, newStatus);
      await handleOnModifyJobAssignment(idJobs);
      await getAssignmentRequests({});
      toastSuccess(t("change_job_status_success"));
    } catch (err: any) {
      toastError(t(err.response.data.message));
    }
  };

  const openConfirmModal = (
    title: string,
    message: string,
    confirm: () => void,
    closeAfterConfirm = true
  ): void => {
    setModalType("danger");
    setModalContent(
      <ConfirmModal
        title={title}
        message={message}
        confirm={confirm}
        cancel={closeModal}
        closeAfterConfirm={closeAfterConfirm}
      />
    );
  };

  const openDurationModal = () => {
    setModalContent(<DurationModal />);
  };

  const openExportJobModal = ({
    jobIds,
    job,
    jobName,
    jobType,
    templates,
    tcOffset = 0,
  }: {
    jobIds: number[];
    job?: Job;
    jobName?: string;
    jobType?: string;
    templates?: any[];
    tcOffset?: any;
  }): void => {
    const isMultipleExport = jobIds.length > 1;
    setModalContent(
      <ExportJobModal
        jobTypes={jobTypes}
        jobType={jobType}
        jobName={jobName}
        templates={templates}
        tcOffset={isMultipleExport ? null : tcOffset}
        bulkExport={isMultipleExport}
        confirm={async (fileName, options) =>
          await handleExportJob({
            jobIds,
            fileName,
            format: options.format,
            showSpeakers: options.showSpeakers,
            interval: options.interval,
            flip: options.flip,
            tc: options.tc,
            idDocxTemplate: options.idDocxTemplate,
            encoding: options.encoding,
            docxDefaultSettings: options.docxDefaultSettings,
            zeroSubtitle: options.zeroSubtitle,
            emptySubtitle: options.emptySubtitle,
            autoBreak: options.autoBreak,
          })
        }
        cancel={closeModal}
        disabled={false}
        selectedJobs={selectedJobs || ([job] as Job[])}
      />
    );
  };

  const getIsSplitDisabled = useCallback(
    (jobsRows: JobRow[]) =>
      _.some(jobsRows, (row) => {
        const jobNotReady = row.status !== JobStatus.ready;
        const isSplittedJob = row.job.splitType === JobSplitType.SPLIT;
        const hasAssignments =
          row.job?.assignments?.some(
            (assignment) =>
              !NotActiveAssignmentStatuses.includes(assignment.assignStatus) // means has an active assignment
          ) || false;

        //TODO: uncomment and check that
        // row.job?.assignments?.some(
        //   (ass) =>
        //     ![
        //       AssignStatus.removed,
        //       AssignStatus.resigned,
        //       AssignStatus.rejected,
        //     ].includes(ass.assignStatus) // means has relevant assignment
        // ) || false;
        const invalidDuration = row.duration < 120;
        return (
          jobNotReady || isSplittedJob || hasAssignments || invalidDuration
        );
      }),
    []
  );

  const jobHasPendingIndexing = useCallback((job: Job) => {
    const enrichTimeout = subHours(new Date(), ENRICH_TIMEOUT_IN_HOURS);
    return job.indexSearch?.some((indexSearch) => {
      const indexSearchIsOld = new Date(indexSearch.createdAt) < enrichTimeout;
      return (
        indexSearch.status === EnrichRequestStatus.PENDING && !indexSearchIsOld
      );
    });
  }, []);

  const isJobReadyForRerun = useCallback((job: Job) => {
    const isTranslationTarget = job.translation === JobTranslationType.TARGET;
    const hasAllowedStatus = RerunJobStatuses.includes(job.status);
    return !isTranslationTarget && hasAllowedStatus;
  }, []);

  const handleOnJobSplit = async ({
    idJobs,
    splitCount,
  }: {
    idJobs: number[];
    splitCount: number;
  }) => {
    try {
      await jobService.split({ idJobs, splitCount });
      await handleOnModifyJobAssignment(idJobs);
      toastSuccess(t("job_split_success"));
    } catch (e) {
      toastError(t("job_split_fail"));
      console.log("error :", e);
    } finally {
      closeModal();
    }
  };

  const handleRerunSuccess = (results: { job: Job; success: boolean }[]) => {
    if (results.some((r) => !r.success)) {
      toastWarning(t("some_jobs_could_not_rerun"));
    } else {
      toastSuccess(t("rerun_succeed"));
    }
    setJobs((prevJobs) => {
      const updatedJobs = [...prevJobs];
      _.each(results, (result) => {
        const jobIndex = _.findIndex(updatedJobs, { idJob: result.job.idJob });
        updatedJobs[jobIndex] = result.job;
      });
      return updatedJobs;
    });
  };

  const handleRerunJob = async (rerunPayload: RerunPayload) => {
    try {
      const result = await jobService.rerun(rerunPayload);
      handleRerunSuccess(result);
      closeModal();
    } catch (e) {
      toastError(t("rerun_error"));
      console.log("error :", e);
    }
  };

  const handleCreateJobTranslationFromJob = async ({
    idJobs,
    translationData,
  }: {
    idJobs: number[];
    translationData: TranslationData[];
  }) => {
    try {
      await jobService.createJobTranslationFromJob({
        idJobs,
        translationData,
      });
      toastSuccess(t("translation_success"));
    } catch (e) {
      toastError(t("translation_failed"));
      console.log("error :", e);
    } finally {
      closeModal();
    }
  };

  const openSplitJobModal = (job: Job) => {
    setModalContent(
      <SplitJobModal
        jobLength={job.duration || 0}
        idJobs={[job.idJob]}
        onApprove={handleOnJobSplit}
        onCancel={closeModal}
      />
    );
  };

  const openRerunModal = (jobs: Job[]) => {
    setModalContent(
      <RerunModal
        jobs={jobs}
        onApprove={handleRerunJob}
        onCancel={closeModal}
        config={config}
      />
    );
  };

  const openCreateTranslationModal = (job: Job) => {
    setModalContent(
      <CreateTranslationModal
        jobs={[job]}
        languages={languages.map((l) => l.value)}
        onApprove={handleCreateJobTranslationFromJob}
        onCancel={closeModal}
      />
    );
  };

  const handleAttachmentUpload = (attachment: File, row?: JobRow) => {
    const clientId =
      row?.client.idClient ||
      (selectedRows && selectedRows.map((r) => r.client.idClient)[0]);
    return uploadAttachment(
      AttachmentType.NOTE,
      attachment,
      row ? [row?.id as number] : selectedRows.map((r) => r.id),
      clientId
    );
  };

  const handleAttachmentRemove = (idAttachment: number, row?: JobRow) =>
    openConfirmModal(
      t("delete_attachment"),
      t("are_you_sure"),
      async () => {
        const idJob = row?.id || (selected && selected[0]);
        if (idJob) await removeAttachment(idJob, idAttachment);
      },
      true
    );

  const handleOnJobRequestChange = async (
    action: "reject" | "accept",
    idUsersJobs: number
  ) => {
    switch (action) {
      case "accept":
        await assignmentService.acceptCommunityMemberRequest(idUsersJobs);
        break;
      case "reject":
        await assignmentService.rejectCommunityMemberRequest(idUsersJobs);
        break;
    }
  };

  const handleAfterJobRequestChange = async (idJob: number) => {
    await getAssignmentRequests({});
    await handleOnModifyJobAssignment([idJob]);
  };

  const openAttachmentsModal = (row?: JobRow): void => {
    setModalContent(
      <AttachmentsModal
        cancel={closeModal}
        removeAttachment={async (idAttachment) =>
          await handleAttachmentRemove(idAttachment, row)
        }
        attachments={row ? row.job.attachments || [] : []}
        upload={(attachment: File) => handleAttachmentUpload(attachment, row)}
        update={async (note: string) => {
          await handleJobEdit(row ? [row.id] : selected, { note });
        }}
        handleDownloadAttachment={handleDownloadAttachment}
        note={row?.job.note || ""}
      />
    );
  };

  const handleDownloadAttachment = async (attachment: Attachment) => {
    await attachmentService.handleDownloadAttachment(attachment.idAttachment);
  };

  const getValidationsByClientAndJobTypes = (job?: Job) => {
    if (!isSameClient) return [];
    const _job =
      job || (!_.isEmpty(selectedRows) ? selectedRows[0].job : undefined);
    return (
      _job?.client?.validations
        ?.filter((v) => _.isEqual(v.jobType, _job?.type?.typeName))
        .map((v) => ({
          label: v?.settings?.general_lang?.presetName,
          value: v?.idValidationPreset,
        })) || []
    );
  };

  const handleAddJobBonus = async ({
    idJobs,
    jobBonus,
  }: {
    idJobs?: number[];
    jobBonus?: Partial<JobBonus>;
  }) => {
    if (!jobBonus || !idJobs) return;
    const bonusToAdd: Partial<JobBonus> = {
      bonusType: jobBonus.bonusType as BonusType,
      createdBy: jobBonus.createdBy || 0,
      qc: jobBonus.qc || 0,
      transcribe: jobBonus.transcribe || 0,
    };
    const changes: JobBonus[] = await jobService.createJobsBonus(
      bonusToAdd,
      idJobs
    );
    return changes[0];
  };

  const handleChangeJobInvoice = async ({
    idJobs,
    jobInvoice,
  }: {
    idJobs?: number[];
    jobInvoice?: Partial<JobInvoice>;
  }) => {
    if (!jobInvoice || !idJobs) return;
    const newJobInvoice = await invoiceService.upsertJobsInvoice({
      jobInvoice,
      idJobs,
    });
    return newJobInvoice;
  };

  const openEditJobModal = async ({
    job,
    jobIds,
  }: {
    job?: Job;
    jobIds?: number[];
  }) => {
    if (!job && !jobIds) return;
    job && updateJobRow(job.idJob, { isLoading: true });
    const validations = getValidationsByClientAndJobTypes(job);
    const transcribePriceDisabled = job
      ? archivedStatuses.transcriber.includes(job?.status)
      : hasCommonItem(
          selectedRows.map((row) => row.status),
          archivedStatuses.transcriber
        );
    const qcPriceDisabled = job
      ? archivedStatuses.qc.includes(job?.status)
      : hasCommonItem(
          selectedRows.map((row) => row.status),
          archivedStatuses.qc
        );
    const jobMedia = job?.idJob
      ? await mediaService.getJobMedia(job.idJob)
      : null;
    setModalContent(
      <JobEditModal
        cancel={closeModal}
        confirm={async ({
          newJobValue,
          jobBonus,
          jobInvoice,
          jobUpdateOptions,
        }: {
          newJobValue?: Partial<Job>;
          jobBonus?: Partial<JobBonus>;
          jobInvoice?: Partial<JobInvoice>;
          jobUpdateOptions?: JobUpdateOptions;
        }) => {
          const idJobs = job ? [job.idJob] : jobIds;
          if (jobBonus) {
            const newBonus = await handleAddJobBonus({
              idJobs,
              jobBonus,
            });
            if (newBonus) {
              newJobValue = {
                ...newJobValue,
                jobBonus: _.omit(newBonus, "idJob") as JobBonus,
              };
            }
          }
          if (jobInvoice) {
            const isInvoicesEqual = _.isEqual(
              jobInvoice,
              job?.jobInvoice || {}
            );

            if (!isInvoicesEqual) {
              const newJobInvoice = await handleChangeJobInvoice({
                idJobs,
                jobInvoice,
              });
              if (newJobInvoice) {
                newJobValue = {
                  ...newJobValue,
                  jobInvoice: newJobInvoice,
                };
              }
            }
          }
          if (newJobValue) {
            await handleJobEdit(
              job ? [job.idJob] : (jobIds as number[]),
              newJobValue,
              jobUpdateOptions
            );
          }
        }}
        job={job}
        media={jobMedia}
        validations={validations}
        transcribePriceDisabled={transcribePriceDisabled}
        qcPriceDisabled={qcPriceDisabled}
        selectedJobs={selectedJobs || ([job] as Job[])}
        createNewInvoice={createNewInvoice}
        createNewProject={createNewProject}
      />
    );
    job && updateJobRow(job.idJob, { isLoading: false });
  };

  const afterAssignSubmit = async (idJobs: number[]) => {
    try {
      await getAssignmentRequests({});
      await handleOnModifyJobAssignment(idJobs);
      closeModal();
    } catch (err: any) {
      console.error(err);
      throw err;
    } finally {
      setSelected([]);
    }
  };

  const handleBulkAssignIconClick = () => {
    openAssignModal({ jobs: selectedJobs || [] });
  };

  const openAssignModal = ({
    members,
    jobs,
  }: {
    jobs: Job[];
    members?: JobCommunityMembers;
  }) => {
    setModalContent(
      <AssignJobModal
        jobs={jobs}
        members={members}
        afterCancel={closeModal}
        afterSubmit={afterAssignSubmit}
      />
    );
  };

  function getTableContext(row: JobRow): ContextOption[] {
    return [
      {
        name: t("edit"),
        action: () => openEditJobModal({ job: row.job }),
      },
      // {
      //   name: t("history"),
      //   action: console.log,
      // },
      {
        name: t("notes"),
        action: () => openAttachmentsModal(row),
      },
      {
        name: t("assign"),
        action: () =>
          openAssignModal({ jobs: [row.job], members: row.members }),
        disabled: [
          JobStatus.deleted,
          JobStatus.done,
          JobStatus.archive,
          JobStatus.splitting,
          JobStatus.pending_splits_transcription,
        ].includes(row.job.status),
      },
      // {
      //   name: t("download"),
      //   action: console.log,
      // },
      {
        name: t("export_job"),
        action: () =>
          openExportJobModal({
            jobIds: [row.id],
            jobName: `${row.name} - ${row.job.outputLanguage[0]}`,
            jobType: row.job.type.typeName,
            job: row.job,
            tcOffset: getTcOffsetByStartTime(row.job.tcOffsets),
            templates: row?.client.templates,
          }),
        disabled:
          row.status === JobStatus.stt ||
          row.status === JobStatus.pending ||
          row.status === JobStatus.aligning ||
          row.status === JobStatus.stt_fail,
      },
      {
        name: "export_brief",
        action: () => openSummarizeJobModal(row.id, row.name),
        disabled: !isJobReadyForSummarizeOperation(row.job),
      },
      {
        name: t("split"),
        action: () => openSplitJobModal(row.job),
        disabled: getIsSplitDisabled([row]),
      },
      {
        name: t("rerun"),
        action: () => openRerunModal([row.job]),
        disabled:
          !isJobReadyForRerun(row.job) || jobHasPendingIndexing(row.job),
      },
      // {
      //   name: t("tags"),
      //   action: console.log,
      // },
      {
        name: t("create_translation"),
        action: () => openCreateTranslationModal(row.job),
        disabled: !isJobReadyForTranslation(row.job),
      },
      {
        name: t("open_in_ooona"),
        action: () => openJobInOoona(row.job),
        disabled: !isOoonaEditAllowed(row),
      },
      {
        name: t("delete_job"),
        color: "red",
        action: () => handleJobDelete(row),
      },
      // {
      //   name: t("cancel"),
      //   color: "grey",
      //   action: _.noop,
      // },
    ];
  }

  const { headCells } = useHeadCells({
    selectedRows,
    headCellsKeys: [
      "id",
      "name",
      "clientLink",
      "type",
      "members",
      "status",
      "duration",
      "deliveryWithTranscribeDelivery",
      "contextMenu",
    ],
    statusCellDropDownCallBack: (row) => getStatusCellDropDown([row]),
    cellLink: {
      name: (r) => `/job/${r.id}`,
    },
    tableContextCallBack: getTableContext,
    styles: {
      name: {
        width: "20%",
      },
      client: {
        width: "10%",
      },
      members: {
        width: "15%",
      },
      status: {
        width: "12%",
      },
    },
    barColActions: {
      name: [
        {
          name: "notes",
          icon: faNotes,
          action: () => openAttachmentsModal(),
          disabled:
            selected.length < 2 ||
            _.unionBy(selectedRows, "job.client.idClient").length > 1,
        },
        {
          name: "edit",
          icon: faEdit,
          action: () => openEditJobModal({ jobIds: selected }),
          disabled: selected.length < 2,
        },

        // {
        //   name: "move",
        //   icon: faArrowUpRightFromSquare,
        //   action: () => console.log(selected),
        //   disabled: selected.length < 2,
        // },
        // {
        //   name: "download",
        //   icon: faDownload,
        //   action: () => console.log(selected),
        //   disabled: selected.length < 2,
        // },
        {
          name: "fileExport",
          icon: faFileExport,
          action: handleExportMany,
          disabled: selected.length < 2 || !isSameClient,
        },
        {
          name: "rerun",
          icon: faGears,
          action: () => selectedJobs && openRerunModal(selectedJobs),
          disabled:
            selected.length < 2 ||
            !isSameClient ||
            !selectedJobs?.every(isJobReadyForRerun) ||
            selectedJobs?.every(jobHasPendingIndexing),
        },
        // {
        //   name: "tag",
        //   icon: faTag,
        //   action: () => console.log(selected),
        //   disabled: selected.length < 2,
        // },
        {
          name: "status",
          icon: faSquareArrowRight,
          action: handleBulkStatus,
          disabled: shouldDisabledBulkStatus,
          ref: bulkStatusIconRef,
        },
        {
          name: "trash",
          icon: faTrash,
          action: handleDeleteMany,
          disabled: selected.length < 2,
        },
        {
          name: "bulkAssign",
          icon: faUserPlus,
          action: handleBulkAssignIconClick,
          disabled: shouldDisableBulkAssign,
        },
      ],
      contextMenu: [
        //TODO: MAKE STATISTICS ACCURATE
        // {
        //   action: openDurationModal,
        //   icon: faClock,
        //   name: "duration-per-status",
        //   disabled: false,
        //   size: "1rem",
        // },
      ],
    },
  });

  const createJobRow = (job: Job): JobRow => {
    const members = getJobCommunityMembers(job);
    return {
      id: job.idJob,
      name: job.name,
      client: job.client,
      type: job.type.typeName,
      members,
      status: job.status,
      duration: job.duration || 0,
      uploaded: new Date(job.createdAt),
      delivery: new Date(job.delivery),
      contextMenu: null,
      job: job,
      sttProgress: job.sttProgress,
      indexSearch: job.indexSearch,
    };
  };

  useEffect(() => {
    if (_.isArray(jobs) && !isJobsLoading) {
      const newJobRows = jobs.map((j: Job) => createJobRow(j));
      setJobRows(newJobRows);
    }
  }, [jobs]);

  async function onBuildQuery({
    query,
  }: {
    query: QueryParams<JobFilterOptions>;
  }) {
    if (query.search || query.filters) {
      const _queryFilters = { ...query.filters };
      _queryFilters.status = manageOperaJobStatusesForQuery(
        _queryFilters?.status as JobStatus[]
      );
      _queryFilters.status = _queryFilters?.status || availableStatuses;
      query.filters = _queryFilters;
    }

    await getJobs({ query });

    if (_.isEmpty(query?.filters)) {
      await getAssignmentRequests({});
    } else {
      await getAssignmentRequests({
        search: query?.search,
        filters: query?.filters,
      });
    }
  }

  async function openJobInOoona({ idJob }: { idJob: number }) {
    try {
      updateJobRow(idJob, { isLoading: true });
      const ooonaEditorUrl = await jobService.getOoonaEditorUrl(idJob);
      window.open(ooonaEditorUrl);
    } catch (err) {
      toastError(t("ooona_editor_open_failed"));
    } finally {
      updateJobRow(idJob, { isLoading: false });
    }
  }

  const isOoonaEditAllowed = useCallback(
    (jobRow: JobRow) =>
      [
        JobStatus.done,
        JobStatus.ready,
        JobStatus.archive,
        JobStatus.pending_review,
        JobStatus.review,
        JobStatus.transcribe,
      ].includes(jobRow.status) && jobTypesOptions[jobRow.type]?.allowOoona,
    [jobRows]
  );

  const updateJobRow = (idJob: number, rowData: Partial<JobRow>) => {
    const updatedJobRows = _.clone(jobRows);
    const jobRowIndex = jobRows.findIndex((jr) => jr.id === idJob);
    console.log(jobRowIndex);
    updatedJobRows[jobRowIndex] = {
      ...updatedJobRows[jobRowIndex],
      ...rowData,
    };
    console.log(updatedJobRows);
    setJobRows(updatedJobRows);
  };

  return (
    <Grid
      className={"Jobs Page"}
      container
      display={"flex"}
      justifyContent={"center"}
    >
      <Grid item xs={11} mb={3}>
        <h1 className="pageTitle">{t("jobs")}</h1>
        <h3 className="pageSubTitle">
          {`${t("showing")} ${jobRows?.length} ${t("from")} ${totalJobs}`}
        </h3>
      </Grid>
      <Grid item xs={11} mb={3}>
        <SearchAndFilters
          isLoading={isQueryLoading}
          direction={t("dir") as "ltr" | "rtl"}
          onSearch={onSearch}
          filters={filtersScheme}
          searchPlaceholder={t("search")}
          filterBtnTitle={t("filters")}
          title={t("filter_by")}
          defaultQuery={defaultQuery}
          onFilterChange={onFilterChange}
          onSortChange={onSort}
          sortOptions={sortOptions}
        />
      </Grid>
      <Grid
        item
        xs={11}
        mb={3}
        sx={{
          cursor: assignmentRequests.length > 0 ? "pointer" : "auto",
          userSelect: assignmentRequests.length > 0 ? "none" : "auto",
        }}
        onClick={() => setIsAssignmentRequestsOpen(!isAssignmentRequestsOpen)}
      >
        <Box
          display={"inline-flex"}
          alignItems={"center"}
          gap={1}
          className="pageSubTitle"
        >
          {t("awaiting_your_acceptance")} ({assignmentRequests.length})
          {assignmentRequests.length > 0 && (
            <FontAwesomeIcon
              className={classNames("openAvailableJobsArrow", {
                open: isAssignmentRequestsOpen,
              })}
              icon={faChevronRight}
            />
          )}
        </Box>
      </Grid>
      <Grid item xs={11} className={"assignmentRequestsContainer"} pb={3}>
        <ScrollerContainer style={{ gap: "24px" }}>
          {isAssignmentRequestsOpen
            ? !isAssignmentsLoading &&
              assignmentRequests.map((ar) => (
                <React.Fragment key={ar.job.idJob}>
                  <JobRequest
                    assignmentRequest={ar}
                    onJobRequestChange={handleOnJobRequestChange}
                    afterJobRequestChange={handleAfterJobRequestChange}
                  />
                </React.Fragment>
              ))
            : null}
        </ScrollerContainer>
      </Grid>
      <Grid item xs={11}>
        <ContextMenu
          onClose={contextMenu.onClose}
          anchorEl={contextAnchorEl}
          open={isContextMenuOpen}
          setIsOpen={setIsContextMenuOpen}
          context={contextMenu.content}
        />
        <GeneralTable
          headCells={headCells}
          rows={jobRows}
          selected={selected}
          setSelected={setSelected}
          onLoadMore={onScroll}
          loading={isQueryLoading}
          hasMore={hasMore}
        />
      </Grid>
    </Grid>
  );
};
