import React, { FC, useEffect } from "react";
import config from "./config";
import {
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { faBan } from "@fortawesome/pro-light-svg-icons";

import { sumitLogoBlack } from "@sumit-platforms/common-assets/images";
import {
  BreathingLoader,
  ErrorPage,
  PrivateRoute,
} from "@sumit-platforms/ui-bazar";
import {
  useKeyboardShortcuts,
  useProjectsSettings,
  useSpeakersDocxSettings,
  useTableDocxSettings,
  useValidationsSettings,
  useAuth,
  useFeatureFlag,
  useMFA,
} from "@sumit-platforms/ui-bazar/hooks";
import {
  Downtime,
  MFAPage,
  VerifySMSCode,
  VerifyOTPCode,
} from "@sumit-platforms/ui-bazar/pages";
import authService from "./services/authService";

import Guest from "./layouts/Guest/Guest";
import { Dashboard } from "./layouts/Dashboard/Dashboard";
import { authMap } from "./layouts/Dashboard/menuItems";

//PAGES
import SignUpPage from "./pages/Auth/SignUpPage";
import ResetPasswordPage from "./pages/Auth/ResetPasswordPage";
import { Jobs } from "./pages/Jobs/Jobs";
import { Clients } from "./pages/Clients/Clients";
import { Client } from "./pages/Client/Client";
import { NotFound } from "./pages/NotFound/NotFound";
import { SettingsEditor } from "./pages/SettingsEditor/SettingsEditor";
import UploadFiles from "./pages/UploadFiles/UploadFiles";
import { Community } from "./pages/Community/Community";
import MyJobs from "./pages/MyJobs/MyJobs";
import Wallet from "./pages/Wallet/Wallet";
import Job from "./pages/Job/Job";
import AvailableJobs from "./pages/AvailableJobs/AvailableJobs";
import PreLogin from "./pages/Auth/PreLoginPage";
import { MFAMethods } from "@sumit-platforms/types";

import "react-virtualized/styles.css";

export const Delivery: FC = () => {
  const { t } = useTranslation();
  const isDowntime = useFeatureFlag("opera_downtime");
  const { user, roles, handleUserAuthentication, isLoading, signOut } = useAuth(
    {
      config,
      getUserDetails: authService.getUser,
    }
  );
  const {
    enrollSMS,
    enrollTOTP,
    verifySMSCode,
    validateMfaSession,
    verifyOTPCode,
    resendSMS,
  } = useMFA({
    config,
    onEnrollSuccess: (type, qrCode) => {
      handleUserAuthentication();
      router.navigate(`/mfa/${type}`, { state: { qrCode, dan: "dan" } });
    },
    onEnrollFail: (error, type) => {
      if (error === "already_enrolled") {
        handleUserAuthentication();
        router.navigate(`/mfa/${type}`);
      }
    },
    onVerificationSuccess: () => {
      handleUserAuthentication();
      router.navigate("/");
    },
  });
  const { initShortcuts } = useKeyboardShortcuts();

  useEffect(() => {
    initShortcuts(user?.settings);
  }, [initShortcuts, user?.settings]);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Dashboard />,
      children: [
        {
          path: "",
          element: (
            <PrivateRoute
              userStatus={user?.status}
              userRoles={roles}
              authConfig={authMap["/"]}
              fallbackRoute={"/my-jobs"}
            >
              <Jobs />
            </PrivateRoute>
          ),
        },
        {
          path: "/my-jobs",
          element: (
            <PrivateRoute
              userStatus={user?.status}
              userRoles={roles}
              authConfig={authMap.myJobs}
            >
              <MyJobs />
            </PrivateRoute>
          ),
        },
        {
          path: "available-jobs",
          element: (
            <PrivateRoute
              userStatus={user?.status}
              userRoles={roles}
              authConfig={authMap.availableJobs}
            >
              <AvailableJobs />
            </PrivateRoute>
          ),
        },
        {
          path: "job/:idJob",
          element: <Job />,
        },
        {
          path: "clients/*",
          element: (
            <PrivateRoute
              userStatus={user?.status}
              userRoles={roles}
              authConfig={authMap.clients}
              fallbackRoute={"/available-jobs"}
            >
              <Routes>
                <Route path="" element={<Clients />} />
                <Route path=":idClient" element={<Client />} />
                <Route path="/uploads/:idClient" element={<UploadFiles />} />
                <Route
                  path=":idClient/template/speakers/:id"
                  element={
                    <SettingsEditor
                      useSettings={useSpeakersDocxSettings}
                      settingsType={"template"}
                    />
                  }
                />
                <Route
                  path=":idClient/template/table/:id"
                  element={
                    <SettingsEditor
                      useSettings={useTableDocxSettings}
                      settingsType={"template"}
                    />
                  }
                />
                <Route
                  path=":idClient/validation/:id"
                  element={
                    <SettingsEditor
                      useSettings={useValidationsSettings}
                      settingsType={"validation"}
                    />
                  }
                />
                <Route
                  path=":idClient/project/:id"
                  element={
                    <SettingsEditor
                      useSettings={useProjectsSettings}
                      settingsType={"project"}
                    />
                  }
                />
              </Routes>
            </PrivateRoute>
          ),
        },
        {
          path: "community/*",
          element: (
            <PrivateRoute
              userStatus={user?.status}
              userRoles={roles}
              authConfig={authMap.community}
            >
              <Routes>
                <Route path="" element={<Community />} />
                <Route path=":idUser" element={<Community />} />
              </Routes>
            </PrivateRoute>
          ),
        },
        {
          path: "wallet",
          element: (
            <PrivateRoute
              userStatus={user?.status}
              userRoles={roles}
              authConfig={authMap.wallet}
            >
              <Wallet />
            </PrivateRoute>
          ),
        },
        {
          path: "unauthorized",
          element: (
            <ErrorPage
              title={t("unauthorized")}
              text={t("unauthorized_text")}
              icon={faBan}
              redirectToOnClick={"/"}
            />
          ),
        },
        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
    {
      path: "/",
      element: <Guest />,
      children: [
        {
          path: "signup",
          element: <SignUpPage />,
        },
        {
          path: "login",
          element: <PreLogin />,
        },
        {
          path: "password-reset",
          element: <ResetPasswordPage />,
        },
        {
          path: "mfa",
          element: (
            <MFAPage
              enrollSMS={enrollSMS}
              validateMfaSession={validateMfaSession}
              enrollTOTP={enrollTOTP}
            />
          ),
        },
        {
          path: "mfa/sms",
          element: (
            <VerifySMSCode
              verifySMSCode={verifySMSCode}
              resendCode={resendSMS}
              logOut={signOut}
              validateMfaSession={validateMfaSession}
            />
          ),
        },
        {
          path: "mfa/totp",
          element: (
            <VerifyOTPCode
              verifyOTPCode={verifyOTPCode}
              type={MFAMethods.totp}
              validateMfaSession={validateMfaSession}
              logOut={signOut}
            />
          ),
        },
        {
          path: "*",
          element: <PreLogin />,
        },
      ],
    },
  ]);

  if (isDowntime) return <Downtime />;
  return isLoading ? (
    <BreathingLoader
      className={isLoading ? "fadeIn" : "fadeOut"}
      image={sumitLogoBlack}
    />
  ) : (
    <RouterProvider router={router}></RouterProvider>
  );
};
