import React, { FC, useState } from "react";
import { Grid } from "@mui/material";
import { User } from "@sumit-platforms/types";
import { Input, Button } from "@sumit-platforms/ui-bazar";

export interface UserEditModalProps {
  confirm: (userData: Partial<User>) => Promise<void>;
  cancel: () => void;
  user: {
    firstName: string;
    lastName: string;
    phone: string;
  };
}

export const UserEditModal: FC<UserEditModalProps> = ({
  confirm,
  cancel,
  user,
}) => {
  const [firstName, setFirstName] = useState(user?.firstName || "");
  const [lastName, setLastName] = useState(user?.lastName || "");
  const [phone, setPhone] = useState(user?.phone || "");

  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = () => {
    setIsLoading(true);
    confirm({ firstName, lastName, phone }).then(() => {
      setIsLoading(false);
    });
  };

  return (
    <Grid
      container
      px={4}
      pt={4}
      pb={2}
      spacing={2}
      className="UserEditModal"
      width="400px"
    >
      <Grid item xs={12}>
        <Input
          label="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target?.value || "")}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          label="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target?.value || "")}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          label="Phone Number"
          value={phone}
          onChange={(e) => setPhone(e.target?.value || "")}
        />
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center" gap={2}>
        <Button variant="outlined" onClick={cancel} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleConfirm}
          loading={isLoading}
        >
          Confirm
        </Button>
      </Grid>
    </Grid>
  );
};
