import config from "./../config";
import axios from "axios";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import FirebaseService from "./firebaseService";
import { User } from "@sumit-platforms/types";

const endpoint = `${config.server.host}/${config.server.users}`;

const AuthService = () => {
  const auth = getAuth(FirebaseService);
  auth.tenantId = config.firebase.tenantId;

  const getToken = async (forceRefresh?: boolean) => {
    const user = auth.currentUser;
    const userToken = await user?.getIdToken(forceRefresh);
    return userToken;
  };

  const resetPassword = async (email: string) => {
    await axios.post(`${endpoint}/resetPassword`, { email });
  };

  const logout = () => {
    if (!auth) return;
    axios.get(`${endpoint}/logout`);
    auth.signOut();
    localStorage.clear();
  };

  const signUp = async (newUserDetails: any, invitationToken: string) => {
    try {
      const newUser: User = (
        await axios.post<User>(endpoint + "/createUserFromInvitation", {
          newUserDetails: {
            firstName: newUserDetails.firstName,
            lastName: newUserDetails.lastName,
            password: newUserDetails.password,
            phone: newUserDetails.phone,
            email: newUserDetails.email,
            languages: newUserDetails.languages,
            country: newUserDetails.country,
          },
          token: invitationToken,
        })
      ).data;
      return newUser;
    } catch (err: any) {
      throw err.response.data;
    }
  };

  const getUser = async () => {
    try {
      const userData = await axios.get(`${endpoint}/getLoggedInUser`, {
        withCredentials: true,
      });
      if (userData.status !== 200) {
        throw new Error("Failed to get user detail");
      }
      return userData.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  return {
    getToken,
    logout,
    signUp,
    auth,
    getUser,
    resetPassword,
  };
};

export default AuthService();
