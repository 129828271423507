import React from "react";
import { useTranslation } from "react-i18next";
import { sumitLogoBlack } from "@sumit-platforms/common-assets/images";

import "./DowntimePage.scss";

export const Downtime = () => {
  const { t } = useTranslation();
  return (
    <div className="DowntimePage">
      <div className="downtimeContainer">
        <div className="downtimeContent">
          <div className="headerTitle">
            <img src={sumitLogoBlack} alt="Sumit-AI" className="headerLogo" />
          </div>
          <h1 className="downtimeTitle">{t("downtime_title")}</h1>
          <p className="downtimeMessage">{t("downtime_message")}</p>
          <p className="downtimeApology">{t("downtime_apology")}</p>
          <p className="downtimeEstimate">{t("downtime_estimate")}</p>
          <div className="downtimeContact">
            <h3 className="downtimeContactTitle">
              {t("downtime_contact_title")}
            </h3>
            <p className="downtimeEmail">
              {t("downtime_email")}:{" "}
              <a href={`mailto:${t("downtime_email")}`}>
                {t("downtime_email")}
              </a>
            </p>
            <p className="downtimeWhatsapp">
              WhatsApp:{" "}
              <a
                href={`https://wa.me/${t("downtime_whatsapp").replace(
                  /\D/g,
                  ""
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("downtime_whatsapp")}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
