import axios from "axios";

const MediaService = ({ config }: { config: any }) => {
  const endpoint = `${config.server.host}/${config.server.media}`;

  const downloadMediaById = async (idMedia: number) => {
    try {
      const { data } = await axios.get(`${endpoint}/download/${idMedia}`);
      return data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const getJobMedia = async (idJob: number) => {
    try {
      const { data } = await axios.get(`${endpoint}/job/${idJob}`);
      return data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const downsampleMedia = async (idMedia: number) => {
    try {
      const { data } = await axios.get(`${endpoint}/${idMedia}/downsample`);
      return data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const setMainMedia = async (idMedia: number) => {
    try {
      const { data } = await axios.put(`${endpoint}/${idMedia}/setMain`);
      return data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const deleteMedia = async (idMedia: number) => {
    try {
      const { data } = await axios.delete(`${endpoint}/${idMedia}`);
      return data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  return {
    getJobMedia,
    downloadMediaById,
    downsampleMedia,
    setMainMedia,
    deleteMedia,
  };
};

export default MediaService;
